const PUBLIC_CDN_URL = "https://pcl-cdn.playdonut.com";
const PUBLIC_WEB_URL = "https://devpcl.playdonut.com";

const extNum = (str )=> {
    if( typeof str !== "string" ) return str;
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
}

const gameProcessType = {
    TYPE1 : "TYPE1",
    TYPE2 : "TYPE2",
    TYPE3 : "TYPE3",
    TYPE4 : "TYPE4",
}

const findExposureTag = (data, tag) => {
    const findId = Object.keys(data).find( key => key == tag );
    return findId ? data[findId] : data["default"];
}

export default {
    PUBLIC_CDN_URL,
    extNum,
    gameProcessType,
    findExposureTag

}
