const Elem = (window as any).Elem;
const EventType = (window as any).EventType;
const PlayerType = (window as any).PlayerType;
const CbPlayerInfoType = (window as any).CbPlayerInfoType;
const ScreenType = (window as any).ScreenType;
const Singleton = (window as any).Singleton;

class WindowDefine {
    static get ElemDefine() {
        return (window as any).ElemDefine;
    }

    static get BrandBarElem() {
        return (window as any).BrandBarElem;
    }

    static get BrandIconElem() {
        return (window as any).BrandIconElem;
    }

    static get CategoryIconElem() {
        return (window as any).CategoryIconElem;
    }

    static get CenterBoxPlayerElem() {
        return (window as any).CenterBoxPlayerElem;
    }

    static get CharBannerElem() {
        return (window as any).CharBannerElem;
    }

    static get ContentBannerElem() {
        return (window as any).ContentBannerElem;
    }

    static get CurationBannerElem() {
        return (window as any).CurationBannerElem;
    }

    static get FeaturedBannerElem() {
        return (window as any).FeaturedBannerElem;
    }

    static get FeaturedIconElem() {
        return (window as any).FeaturedIconElem;
    }

    static get FullScreenElem() {
        return (window as any).FullScreenElem;
    }

    static get MusicStationElem() {
        return (window as any).MusicStationElem;
    }

    static get UtilBarElem() {
        return (window as any).UtilBarElem;
    }

    static get YoutubeManager() {
        return (window as any).YoutubeManager;
    }

    static get UIManager() {
        return (window as any).UIManager;
    }



}

class CustomLogger {
    //@ts-ignore
    event(...args?: any[] | null | undefined ) {
        const hasLog = false;
        const eventStyle = 'color: rgb(103 247 129); font-weight: bold;';
        if( hasLog ){
            console.log('%c[UI] :', eventStyle, args);
        }
    }
}

const logger = new CustomLogger();

export {
    Elem, EventType, logger, PlayerType, CbPlayerInfoType, ScreenType, WindowDefine, Singleton
};
