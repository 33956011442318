//@ts-nocheck
import {EventType, PlayerType, Singleton, WindowDefine} from "@/src/js/define/core/Declare";

class UIManager extends Singleton {

    constructor() {
        super();

        this.playerTimer = null;
        this.playerDelay = 500;
        this.playerStateDelay = 100;
    }

    async startup(){
        // @ts-ignore
        await this.initDataDefine();
        await this.initElemDefine();
        await this.initApp();
    }

    /**
     * UI 초기화
     */

    initUI(){
        this.initTheme();
        this.initCustomApp();
    }

    initCustomApp(){
        // $(".logo_div").on("click", function(){
        //     CommandManager.ins().send({command:"localexec",file:"./data/playdonut/app.exe"});
        // });
    }



    initTheme(){
        var themeArr = ['theme_green', 'theme_yellow', 'theme_red', 'theme_purple', 'theme_mint', "theme_pink", "theme_forest", "theme_black"];
        var random = Math.floor(Math.random() * themeArr.length);
        (document.getElementById('wrap') as HTMLElement).className = themeArr[random];
    }

    /**
     * Element 초기화
     */
    async initElemDefine(){
        await WindowDefine.ElemDefine.ins().add( WindowDefine.BrandBarElem.create() ).init();
        await WindowDefine.ElemDefine.ins().add( WindowDefine.BrandIconElem.create() ).init();
        await WindowDefine.ElemDefine.ins().add( WindowDefine.CategoryIconElem.create() ).init();
        await WindowDefine.ElemDefine.ins().add( WindowDefine.CenterBoxPlayerElem.create() ).init();
        await WindowDefine.ElemDefine.ins().add( WindowDefine.CharBannerElem.create() ).init();
        await WindowDefine.ElemDefine.ins().add( WindowDefine.FeaturedIconElem.create() ).init();
        await WindowDefine.ElemDefine.ins().add( WindowDefine.ContentBannerElem.create() ).init();
        await WindowDefine.ElemDefine.ins().add( WindowDefine.CurationBannerElem.create() ).init();
        await WindowDefine.ElemDefine.ins().add( WindowDefine.FeaturedBannerElem.create() ).init();
        await WindowDefine.ElemDefine.ins().add( WindowDefine.MusicStationElem.create() ).init();
        await WindowDefine.ElemDefine.ins().add( WindowDefine.UtilBarElem.create() ).init();
        await WindowDefine.ElemDefine.ins().add( WindowDefine.FullScreenElem.create() ).init();

        //@ts-ignore
        window.YoutubeManagerIns = WindowDefine.YoutubeManager.ins();
    }
    /**
     * Data 초기화
     * dummyData.json 주입
     */
    async initDataDefine( _mq:any ){
        await (window as any ).DataDefine.ins().initData();
    }

    initApp(){

        UIManager.ins().initUI();

        $(document).trigger( EventType.UI_INIT_EVENT);

        this.startVideo();


    }

    /**
     * 시작 영상 재생
     */
    startVideo(){
        const startVideo = DataDefine.ins().find(DataDefine.START_VIDEO);
        const screenType = startVideo !== undefined && startVideo["videoProcessType"];

        const isPlayerDisabled  = DataDefine.ins().find( DataDefine.PCROOM_DATA )?.pcroom[PlayerType.START_VOD];

        LogManager.send({logType:"startVideo", data: startVideo, isPlayerDisabled: isPlayerDisabled });
        if( isPlayerDisabled ){
            return;
        }
        setTimeout(function(){
            if( screenType === "FS" ) {
                WindowDefine.ElemDefine.ins().find( WindowDefine.FullScreenElem).startup();
            }
            else if( screenType === "CB" ){
                WindowDefine.ElemDefine.ins().find( WindowDefine.CenterBoxPlayerElem ).startup();
            }
            if( startVideo?.videoEventCodeView ){
                GAManager.send({ eventCode : startVideo?.videoEventCodeView, adType : "VIDEO", type : startVideo?.contentType });
            }

        }, 4000 )
    }






}

export default UIManager;
