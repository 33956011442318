import {
    Elem,
    EventType,
} from "@/src/js/define/core/Declare";

class ContentBannerElem extends Elem {

    constructor() {
        super();
    }

    init() {
        this.events();
    }

    events() {
        $(".brand_icon_box>button").on(EventType.CLICK, function () {
            console.log(", .brand_icon_box>button, click");
        });
    }

}

export default ContentBannerElem;
