//@ts-nocheck

import { Elem, logger, WindowDefine, ScreenType, PlayerType } from "@/src/js/define/core/Declare";
class FullScreenElem extends Elem{

    constructor() {
        super();
        // 삭제 타입
        this.isRemoveType = false;
    }

    startup(){
        const startVod = (window as any).DataDefine.ins().find(DataDefine.START_VIDEO )
        WindowDefine.ElemDefine.ins().find( WindowDefine.CenterBoxPlayerElem ).removePlayer();
        this.createPlayer( startVod["youtubeId"], { PlayerType : PlayerType.START_VOD, currentData: startVod } );
    }

    createPlayer(ytId:string, options: { PlayerType?: string, currentData?:any } = {} ){
        const { PlayerType = "NONE" , currentData } = options;
        const notAccessYoutube = window?.__PRELOADED_MAIN_STATE__?.NOT_ACCESS_YOUTUBE ?? false;
        if( notAccessYoutube ){
            return;
        }

        const isPlayerDisabled = DataDefine.ins().find( DataDefine.PCROOM_DATA ).pcroom[PlayerType];
        if( isPlayerDisabled ){
            return;
        }

        try{
            if( !this.isRemoveType  ){
                if( $(".fullScreenFrame").length > 0 ){
                    $(".fullScreenFrame").show();
                    ( ( document.querySelector(".fullScreenFrame") as HTMLIFrameElement).contentWindow as any ).changeVideoId( ytId, PlayerType , currentData?.videoPlayTime ?? 0 );
                }else{
                    (document.getElementById("full_screen_box") as HTMLElement).innerHTML =
                        WindowDefine.YoutubeManager.ins().getFUllScreenPlayer( ytId, PlayerType , currentData?.videoPlayTime ?? 0 );
                }
            }else{
                (document.getElementById("full_screen_box") as HTMLElement).innerHTML =
                    WindowDefine.YoutubeManager.ins().getFUllScreenPlayer( ytId , PlayerType, currentData?.videoPlayTime ?? 0 );
            }
        }catch (e){
            logger.event("fullScreenFrame changeVideoId Error");
            WindowDefine.YoutubeManager.ins().reset(ScreenType.FULL);
        }

        console.log(JSON.stringify({ command: 'cmd_to_main', sub_cmd: 'resize_height', height:50 }));


    }

    removePlayer(){
        this.clear();
    }

    clear(){
        if( !this.isRemoveType ){
            if( document.querySelector(".fullScreenFrame") ){
               try{
                   ( ( document.querySelector(".fullScreenFrame") as HTMLIFrameElement).contentWindow as any ).cont_youtube( "pause" );
               }catch(e){

               }
               $(".fullScreenFrame").hide();
            }
        }else{
            (document.getElementById("full_screen_box") as HTMLElement).innerHTML = "";
        }
        console.log(JSON.stringify({ command: 'cmd_to_main', sub_cmd: 'resize_height', height:412 }));
    }

    mute(){
        if( document.querySelector(".fullScreenFrame") ){
            ( ( document.querySelector(".fullScreenFrame") as HTMLIFrameElement).contentWindow as any ).cont_youtube( "mute" );
        }
    }

    unmute(){
        if( document.querySelector(".fullScreenFrame") ){
            ( ( document.querySelector(".fullScreenFrame") as HTMLIFrameElement).contentWindow as any ).cont_youtube( "unMute" );
        }
    }

}

export default FullScreenElem;
