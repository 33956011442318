import ServiceManager from "@/server/lib/ServiceManager.js";
import DataDefine from "@/src/js/define/manager/DataDefine.js";
import Helper from "@/src/helper/Helper.js";

/**
 * 커멘드 관리자.
 * @class
 * @property {function} openCategory -
 * 디스플레이|마우스|사운드 설정 메서드.
 * <pre><code> {command: 'setup', value: 'screen | mouse | sound' }<pre><code>
 *
 * @property {function} openDisplay - 디스플레이 설정 창 메서드.
 * <pre><code> {command: 'setup', value: 'screen' }<pre><code>
 *
 * @property {function} openMouse - 마우스 설정 창  메서드.
 * <pre><code> {command: 'setup', value: 'mouse' }<pre><code>
 *
 * @property {function} openSound - 사운드 설정 창  메서드.
 * <pre><code> {command: 'setup', value: 'sound' }<pre><code>
 *
 * @property {function} openStep - 발소리 증폭 설정 창 메서드.
 * <pre><code> {command: 'localexec', file: 'xutil_.exe', param: {command: 'soundboost'} }<pre><code>
 *
 * @property {function} openEdge - 엣지 브라우저 오픈  메서드.
 * <pre><code> {command: 'webopen', url: <var class="lit">$url</var>, browser: 'default' }<pre><code>
 *
 * @property {function} openChrome - 크롬 브라우저 오픈  메서드.
 * <pre><code> {command: 'webopen', url: <var class="lit">$url</var>, browser: 'chrome.exe' }<pre><code>
 *
 * @property {function} openWhale - 웨일 브라우저 오픈  메서드.
 * <pre><code> {command: 'webopen', url: <var class="lit">$url</var>, browser: 'whale.exe' }<pre><code>
 *
 * @property {function} openBrandBar - 브랜드바 클릭시 command 메서드.
 * <pre><code> {command: 'webopen', url: <var class="lit">$url</var>, browser: 'chrome.exe' }<pre><code>
 *
 * @property {function} openCharBanner - 캐릭터 배너 클릭시 command 메서드.
 * <pre><code> {command: 'webopen', url: <var class="lit">$url</var>, browser: 'chrome.exe' }<pre><code>
 *
 * @property {function} openFeaturedBanner - 피쳐드 배너 클릭시 command 메서드.
 * <pre><code>url이 있을 경우: {command: 'webopen', url: <var class="lit">$url</var>, browser: 'chrome.exe' }<pre><code>
 *
 * @property {function} openMainIcon - 메인 아이콘 클릭시 command 메서드.
 * <pre><code>url이 있을 경우: {command: 'webopen', url: <var class="lit">$url</var>, browser: 'chrome.exe' } <br>
 * gameName 있을 경우: {command:"gamelist",type:"show",width:<var class="lit">$url</var>,height:<var class="lit">$url</var>} <br>
 * {command: 'excute', name : <var class="lit">$name</var>} <pre><code>
 *
 * @property {function} openSectionScreen - 섹션페이지 게임 목록을 보여주는 메서드.
 * <pre><code> {command:'gamelist',type:<var class="lit">$commandName</var>,width: ,height,left,top }<pre><code>
 *
 * @property {function} gameListExecute - 게임을 실행하는 메서드.
 * <pre><code> {command:"gamelist",type:"show",width:<var class="lit">$url</var>,height:<var class="lit">$url</var>,left:"0",top:"0"} <br>
 * {command: 'excute', name : <var class="lit">$name</var>} <pre><code>
 */

class CommandManager extends Singleton {
    constructor(props) {
        super(props);
        this.commandName = {
            "openDisplay": "openDisplay", // 디스플레이 설정
            "openMouse": "openMouse", // 마우스 설정
            "openSound": "openSound", // 사운드 설정
            "openStep": "openStep", // 발소리 설정
            "openEdge": "openEdge", // 엣지 브라우저 열기
            "openChrome": "openChrome", // 크롬 브라우저 열기
            "openWhale": "openWhale", // 웨일 브라우저 열기
            "openBrandBanner": "openBrandBanner", // 브랜드배너 열기
            "openContentBar": "openContentBar", // 컨텐츠바 열기
            "clickCharBanner": "clickCharBanner", // 캐릭터 배너 클릭
            "top_1": "top_1", // 피쳐드 배너 1 클릭
            "top_2": "top_2", // 피쳐드 배너 2 클릭
            "top_3": "top_3", // 피쳐드 배너 3 클릭
            "top_4": "top_4", // 피쳐드 배너 4 클릭
            "featured_icon_1": "featured_icon_1", // 피쳐드 아이콘 1 클릭
            "featured_icon_2": "featured_icon_2", // 피쳐드 아이콘 2 클릭
            "featured_icon_3": "featured_icon_3", // 피쳐드 아이콘 3 클릭
            "featured_icon_4": "featured_icon_4", // 피쳐드 아이콘 4 클릭
            "featured_icon_5": "featured_icon_5", // 피쳐드 아이콘 5 클릭
            "featured_icon_6": "featured_icon_6", // 피쳐드 아이콘 6 클릭
            "featured_icon_7": "featured_icon_7", // 피쳐드 아이콘 7 클릭
            "featured_icon_8": "featured_icon_8", // 피쳐드 아이콘 8 클릭
            "featured_icon_9": "featured_icon_9", // 피쳐드 아이콘 9 클릭
            "featured_icon_10": "featured_icon_10", // 피쳐드 아이콘 9 클릭
            "toggleSpHs": "toggleSpHs", // 토글 스피커 & 헤드셋

            "curationBanner": "curationBanner", // 큐레이션 배너 클릭

            "musicStation" : "musicStation", // 뮤직스테이션 클릭
        }

    }

    startup() {
        // console.log("CommandManager startup")
        this.addEvents();
    }

    addEvents() {
        const _this = this;
        let $cm = $("[data-command-id]");
        let $section = $("[data-section-id]");

        $("body").on(EventType.CLICK, "[data-command-id]", function (e) {
            _this.command($(this).data("command-id"), $(this).data("command-type"), $(this));
        });

        $section.on(EventType.CLICK, function (e) {
            _this.openSectionScreen({ name : $(this).data("section-id"), isGameNameHide : true, hasExcute : false });
            const category = $(this).data("section-id");
            let adValue = "";
            switch(category) {
                case "hotgame":
                    adValue = "MCI_POPULAR_Clicks";
                    break;
                case "online":
                    adValue = "MCI_ONLINE_Clicks";
                    break;
                case "fps":
                    adValue = "MCI_FPS/SPORTS_Clicks";
                    break;
                case "casual":
                    adValue = "MCI_CASUAL_Clicks";
                    break;
                case "cd":
                    adValue = "MCI_STEAM_Clicks";
                    break;
                case "messenger":
                    adValue = "MCI_OTHERS_Clicks";
                    break;
                case "crossPlatform":
                    adValue = "MCI_CORSS_Clicks";
                    break;
            }
            GAManager.send({ eventCode: adValue });
        });

        $("body").on(EventType.CLICK, "[data-curation-id]", function (e) {
            try{
                const data = DataDefine.ins().find( DataDefine.CURATION_BANNER );
                const findData = data.find( v=> v.position === $(this).data("position") );
                const currentData = findData?.details?.[$(this).data("index")]
                if( currentData.webProcessType === DataDefine.webProcessType.WEB ){
                    _this.webOpen(currentData.landingWebUrl);
                    if( currentData?.landingEventCodeClick ){
                        GAManager.send({ eventCode : currentData?.landingEventCodeClick })
                    }
                }else if( currentData.webProcessType === DataDefine.webProcessType.SECTION ){
                    _this.openSectionScreen({ name : "sectionPage", isGameNameHide : true, sectionPage : currentData.sectionPage });
                }
            }catch (e){

            }
        });
    }

    /**
     * 디스플레이|마우스|사운드 설정 메서드.
     * @param {string} category - 카테고리.
     */
    openCategory(category) {
        this.send({command: 'setup', value: category});
    }

    /**
     * 디스플레이 설정 창 메서드
     */
    openDisplay() {
        this.openCategory('screen');
    }

    /**
     * 마우스 설정 창 메서드.
     */
    openMouse() {
        this.openCategory('mouse');
    }

    /**
     * 사운드 설정 창 메서드.
     */
    openSound() {
        this.openCategory('sound');
    }

    /**
     * 발소리 증폭 설정 창 메서드.
     */
    openStep() {
        this.send({command: 'soundboost', width: SoundboosterType.WIDTH, height: SoundboosterType.HEIGHT, url: process.env.PUBLIC_WEB_URL+'/sound'})
        // 구버전 호환
        // this.send({command:"localexec",file:"xutil_.exe",param:{"command":"soundboost"}});
    }

    /**
     * 웹 브라우저 오픈 메서드.
     * @param {string} url - URL 주소.
     * @param {string} browser - 브라우저 이름.
     */
    webOpen(url, browser="chrome.exe") {
        this.send({command: 'webopen', url: url, browser: browser});
    }

    targetBRS(){
        const targetName = DataDefine.ins().find( DataDefine.BRS_TARGET );
        const targetBrs = DataDefine.ins().find( targetName )
        return { url : targetBrs?.landingWebUrl === "" || !targetBrs?.landingWebUrl ? "https://www.naver.com" : targetBrs.landingWebUrl , eventName : targetBrs?.eventName, state : targetBrs?.state,  contentType : targetBrs?.contentType, targetBrs }
    }

    validationWebOpen( data){
        if( data?.webProcessType === "WEB" ){
            return { url : data?.landingWebUrl, landingEventCodeClick : data?.landingEventCodeClick, state : data?.state }
        }else{
            return null;
        }
    }

    async openBrowser({ browserName, typeName, iconType } ){
        const {  contentType } = this.targetBRS();
        const isStartBrs = contentType === "START_BRS";
        const b = await fetch(`/browser`);
        const data = await b.json();
        const targetBrs = Helper.findExposureTag( isStartBrs? data.data?.START_BRS : data?.data?.END_BRS, window.__PRELOADED_MAIN_STATE__?.pcroomData?.tagId )?.[0] ?? {}

        if( targetBrs?.landingWebUrl ){
            this.webOpen(targetBrs?.landingWebUrl, browserName);
            if( targetBrs?.landingEventCodeImps ){
                GAManager.send( { eventCode : targetBrs?.landingEventCodeImps, contentType : contentType, type: typeName } );
            }
            GAManager.send( { eventCode : contentType === "START_BRS" ? `ICON_${iconType}_S` : `ICON_${iconType}_E`,
                gubun : isStartBrs ? GAManager.GA_GUBUN.START_BRS : GAManager.GA_GUBUN.END_BRS, type: typeName } );
        }else{
            this.webOpen("https://www.naver.com", 'chrome.exe');
        }
    }

    /**
     * 엣지 브라우저 오픈 메서드
     */
    async openEdge() {
        await this.openBrowser( { browserName : 'msedge.exe', typeName : "OPEN_EDGE", iconType : "E" } );
    }

    /**
     * 크롬 브라우저 오픈 메서드.
     */
    async openChrome() {
        await this.openBrowser( { browserName : 'chrome.exe', typeName : "OPEN_CHROME", iconType : "C" } );;
    }

    /**
     * 웨일 브라우저 오픈 메서드.
     */
    async openWhale() {
        await this.openBrowser( { browserName : 'whale.exe', typeName : "OPEN_WHALE", iconType : "W" } );
    }

    /**
     * 캐릭터 배너 클릭시 command 메서드
     */
    openCharBanner() {
        const targetData = this.validationWebOpen( DataDefine.ins().find( DataDefine.CHARACTER_BANNER ) );
        if( targetData ){
            const { url , landingEventCodeClick, state  } = targetData;
            this.webOpen(url, 'chrome.exe');
        }
        GAManager.sendRawData({ dataType: DataDefine.CHARACTER_BANNER, data : DataDefine.ins().find(DataDefine.CHARACTER_BANNER) } );
    }

    /**
     * 브랜드배너 클릭시 command 메서드
     */
    openBrandBanner() {
        const data = DataDefine.ins().find( DataDefine.BRAND_BANNER );
        if( data?.webProcessType === DataDefine.webProcessType.SECTION ){
            if( data?.sectionPage ){
                if( data.sectionPage.gubun === "A" ){
                    this.openSectionScreen({ name : "openBrandBanner", isGameNameHide : true });
                }else{
                    this.openSectionScreen({ name : "openBrandBanner", custom: true, sectionPage : data.sectionPage });
                }
            }
        }else if( data?.webProcessType === DataDefine.webProcessType.WEB ){
            const targetData = this.validationWebOpen( DataDefine.ins().find( DataDefine.BRAND_BANNER ) );
            if( targetData ){
                const { url , landingEventCodeClick, state  } = targetData;
                this.webOpen(url, 'chrome.exe');
            }
        }
        GAManager.sendRawData({ dataType: DataDefine.BRAND_BANNER, data : DataDefine.ins().find( DataDefine.BRAND_BANNER )   } );
    }

    /**
     * 컨텐츠 바 클릭시 command 메서드
     */
    openContentBar() {
        const data = DataDefine.ins().find( DataDefine.CONTENTBAR );
        if( data?.webProcessType === DataDefine.webProcessType.SECTION ){
            if( data?.sectionPage ){
                if( data.sectionPage.gubun === "A" ){
                    this.openSectionScreen({ name : "openContentBar", isGameNameHide : true });
                }else{
                    this.openSectionScreen({ name : "openContentBar", custom: true,  sectionPage : data.sectionPage });
                }
            }
        }else if( data?.webProcessType === DataDefine.webProcessType.WEB ){
            const targetData = this.validationWebOpen( DataDefine.ins().find( DataDefine.CONTENTBAR ) );
            if( targetData ){
                const { url , landingEventCodeClick, state  } = targetData;
                this.webOpen(url, 'chrome.exe');
            }
        }
        GAManager.sendRawData({ dataType: DataDefine.CONTENTBAR, data : DataDefine.ins().find( DataDefine.CONTENTBAR )   } );
    }

    /**
     * 피쳐드 배너 클릭시 command 메서드
     * @param dataID - data ID.
     * @param type - 타입
     */
    openFeaturedBanner(dataID, type ) {
        let data = this.findDataByID(dataID)?.find( v=> {
            return Helper.extNum(v.position) === type
        } ) ?? {}
        if( data?.webProcessType === DataDefine.webProcessType.WEB ){
            this.webOpen(data.landingWebUrl);
            GAManager.sendRawData( { dataType : DataDefine.FEATURED_BANNER, data : data  } );
        }
    }

    /**
     * 메인 아이콘 클릭시 command 메서드
     * @param dataID - data ID.
     * @param type - 타입
     */
    openMainIcon(dataID, type) {
        let data = this.findDataByID(dataID)?.find( v=> Helper.extNum(v.position) === type ) ?? {}
        this.resetLine();
        switch (data?.gameProcessType) {
            case Helper.gameProcessType.TYPE1: // 기본( 섹션+로딩+게임실행)
                this.gameListExecute({ name : data?.game?.name ?? "" , sectionPage : data.sectionPage });
                break;
            case Helper.gameProcessType.TYPE2: // 광고
                this.webOpen(data.gameWebUrl);
                break;
            case Helper.gameProcessType.TYPE3: // 기본+광고(URL+섹션+로딩+게임실행)
                this.webOpen(data.gameWebUrl);
                this.gameListExecute({ name : data?.game?.name ?? "" , sectionPage : data.sectionPage });
                break;
            case Helper.gameProcessType.TYPE4: // 섹션+URL
                this.webOpen(data.gameWebUrl);
                this.openSectionScreen({ name : data?.game?.name ?? "" , sectionPage : data.sectionPage, isGame : true });
                break
        }
        GAManager.sendRawData( { dataType : DataDefine.ICON_MAIN, data } );
    }

    /**
     * 섹션페이지 게임 목록을 보여주는 메서드.
     */
    openSectionScreen({name, isGameNameHide=false, sectionPage, isGame = false, hasExcute = true}) {
        this.send({command:'gamelist',type:name,width:SectionType.WIDTH,height:SectionType.HEIGHT,left:'0',top:'0'});
        if( hasExcute ){
            this.send({command: 'excute', name : { name : name , custom : true, isLoading: false , isGameNameHide, isGame, sectionPage : JSON.stringify(sectionPage) }});
        }

    }

    /**
     * 게임을 실행하는 메서드.
     * @param {string} name - 게임 이름.
     */
    gameListExecute({ name , sectionPage}) {
        this.send({command:"gamelist",type:"show",width:SectionType.WIDTH,height:SectionType.HEIGHT,left:"0",top:"0"});
        this.send({command: 'excute', name : { name : name , custom : true, isLoading: true, sectionPage : JSON.stringify(sectionPage) }});
    }


    toggleSpHs( isHeadset ){
        const param = isHeadset ? "헤드폰,헤드셋,해드폰,해드셋,headset" : "스피커,speaker";
        this.send({command:'localexec',file:'XAudio.exe',param: param});
    }

    command(command , type, $this ) {
        if( command !== this.commandName.musicStation ){
            YoutubeManager.ins().clear();
        }
        switch (command) {
            case this.commandName.openDisplay:
                this.openDisplay();
                break;
            case this.commandName.openMouse:
                this.openMouse();
                break;
            case this.commandName.openSound:
                this.openSound();
                break;
            case this.commandName.openStep:
                this.openStep()
                break;
            case this.commandName.openEdge:
                this.openEdge();
                break;
            case this.commandName.openChrome:
                this.openChrome();
                break;
            case this.commandName.openWhale:
                this.openWhale();
                break;
            case this.commandName.clickCharBanner:
                this.openCharBanner();
                break;
            case this.commandName.top_1:
            case this.commandName.top_2:
            case this.commandName.top_3:
            case this.commandName.top_4:
                this.openFeaturedBanner(DataDefine.FEATURED_BANNER, Helper.extNum( command ));
                break;
            case this.commandName.openBrandBanner :
                this.openBrandBanner();
                break;
            case this.commandName.openContentBar :
                this.openContentBar();
                break;
            case this.commandName.featured_icon_1:
            case this.commandName.featured_icon_2:
            case this.commandName.featured_icon_3:
            case this.commandName.featured_icon_4:
            case this.commandName.featured_icon_5:
            case this.commandName.featured_icon_6:
            case this.commandName.featured_icon_7:
            case this.commandName.featured_icon_8:
            case this.commandName.featured_icon_9:
            case this.commandName.featured_icon_10:
                this.openMainIcon(DataDefine.ICON_MAIN, Helper.extNum( command ));
                break;
            case this.commandName.curationBanner:
                this.webOpen( `https://youtube.com/watch?v=${$this.data("ytid")}` );
            break;
            case this.commandName.musicStation:
                GAManager.send( { eventCode : $this.data("event-code"), adType: "MS", type : "MUSIC_STATION" } );
            break;
        }
    }

    findDataByID(dataID) {
        return DataDefine.ins().find(dataID);
    }

    send(commandData) {
        console.log(JSON.stringify(commandData));
        ServiceManager.cLog(commandData);
    }

    resetLine(){
        // ServiceManager.cLog({command: '----------------------------------------------------------'});
    }

}

export default CommandManager;


