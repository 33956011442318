//@ts-nocheck
import BrandBarElem from "@/src/js/define/element/BrandBarElem";
import BrandIconElem from "@/src/js/define/element/BrandIconElem";
import CategoryIconElem from "@/src/js/define/element/CategoryIconElem.js";
import CenterBoxPlayerElem from "@/src/js/define/element/CenterBoxPlayerElem.js";
import CharBannerElem from "@/src/js/define/element/CharBannerElem.js";
import ContentBannerElem from "@/src/js/define/element/ContentBannerElem.js";
import CurationBannerElem from "@/src/js/define/element/CurationBannerElem.js";
import FeaturedBannerElem from "@/src/js/define/element/FeaturedBannerElem.js";
import FeaturedIconElem from "@/src/js/define/element/FeaturedIconElem.js";
import FullScreenElem from "@/src/js/define/element/FullScreenElem.js";
import MusicStationElem from "@/src/js/define/element/MusicStationElem.js";
import UtilBarElem from "@/src/js/define/element/UtilBarElem.js";

import DataDefine from "@/src/js/define/manager/DataDefine.js";
import ElemDefine from "@/src/js/define/manager/ElemDefine.js";

import YoutubeManager from "@/src/js/define/manager/YoutubeManager.js";
import UIManager from "@/src/js/define/manager/UIManager.js";
import CommandManager from "@/src/js/define/manager/CommandManager.js";

async function init() {
    window.DataDefine = DataDefine;
    window.ElemDefine  = ElemDefine;
    window.BrandBarElem = BrandBarElem;
    window.BrandIconElem = BrandIconElem;
    window.CategoryIconElem = CategoryIconElem;
    window.CenterBoxPlayerElem = CenterBoxPlayerElem;
    window.CharBannerElem = CharBannerElem;
    window.ContentBannerElem = ContentBannerElem;
    window.CurationBannerElem = CurationBannerElem;
    window.FeaturedBannerElem = FeaturedBannerElem;
    window.FeaturedIconElem = FeaturedIconElem;
    window.FullScreenElem = FullScreenElem;
    window.MusicStationElem = MusicStationElem;
    window.UtilBarElem = UtilBarElem;
    window.CommandManager = CommandManager;
    window.YoutubeManager = YoutubeManager;
    window.UIManager = UIManager;
    CommandManager.ins().startup();
    YoutubeManager.ins().startup();
    UIManager.ins().startup();
}

init();
