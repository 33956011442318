//@ts-nocheck
import {CbPlayerInfoType, Elem, EventType, PlayerType, WindowDefine} from "@/src/js/define/core/Declare";
import Helper from "@/src/helper/Helper";

class CharBannerElem extends Elem{

    constructor() {
        super();
    }

    init(){
        this.$content = this.findElem('CharBannerElem');
        this.events();
    }

    events(){
        $("body").on(EventType.MOUSE_ENTER, "[data-pd=CharBannerElem]", function(){
            clearTimeout(WindowDefine.UIManager.ins().playerTimer)
            const dataValue = (window as any).DataDefine.ins().find(DataDefine.CHARACTER_BANNER);

            const isPlayerDisabled = DataDefine.ins().find( DataDefine.PCROOM_DATA ).pcroom[PlayerType.CHAR_BANNER_ELEM];

            if( dataValue === undefined || isPlayerDisabled ) return;

            if( dataValue?.["videoEventCodeView"] ){
                GAManager.send({ eventCode : dataValue["videoEventCodeView"], adType : "VIDEO", type : dataValue?.contentType });
            }
            WindowDefine.UIManager.ins().playerTimer = setTimeout(function(){
                WindowDefine.ElemDefine.ins().find( WindowDefine.CenterBoxPlayerElem ).createPlayer( {
                    ytId : dataValue["youtubeId"],
                    bannerImg : Helper.PUBLIC_CDN_URL+dataValue["playerAttachImagePath"],
                    type : PlayerType.CHAR_BANNER_ELEM,
                    infoType :  CbPlayerInfoType.IMAGE,
                    currentData : dataValue
                })
            }, WindowDefine.UIManager.ins().playerDelay );
        });

        this.$content.on("mouseleave", function(){
            clearTimeout(WindowDefine.UIManager.ins().playerTimer)
            WindowDefine.YoutubeManager.ins().clear();
        });
    }

}

export default CharBannerElem;
