import { Elem , EventType, logger } from "@/src/js/define/core/Declare";

class BrandIconElem extends Elem{

    constructor() {
        super();
    }

    init(){
        this.events();
    }

    events(){
        $(".run_area .content_bn_box").on(EventType.CLICK, function(){
            logger.event( this, ": .run_area .content_bn_box click" );
        });
    }
}

export default BrandIconElem;
