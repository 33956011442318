class YoutubeManager extends Singleton {
    constructor(props) {
        super(props);
    }

    async startup() {
        // console.log("YoutubeManager startup");
    }

    clear(screenType) {
        if (screenType === ScreenType.CENTER_BOX) {
            ElemDefine.ins().find(CenterBoxPlayerElem).clear();
            ElemDefine.ins().find(MusicStationElem).stop()
        } else if (screenType === ScreenType.FULL) {
            ElemDefine.ins().find(FullScreenElem).clear();
            ElemDefine.ins().find(MusicStationElem).stop()
        } else {
            ElemDefine.ins().find(CenterBoxPlayerElem).clear();
            ElemDefine.ins().find(FullScreenElem).clear();
            ElemDefine.ins().find(MusicStationElem).stop()
        }
    }

    play() {
        try {
            document.querySelector(".playerFrame.centerBoxBanner").contentWindow.cont_youtube("play");
            document.querySelector(".playerFrame.fullScreenFrame").contentWindow.cont_youtube("play");
        } catch (e) {

        }
    }

    isPlaying(screenType) {
        if (screenType === ScreenType.FULL) {
            try {
                return document.querySelector(".playerFrame.fullScreenFrame").contentWindow.isPlaying();
            } catch (e) {
                return false;
            }
        } else if (screenType === ScreenType.CENTER_BOX) {
            try {
                return document.querySelector(".playerFrame.centerBoxBanner").contentWindow.isPlaying();
            } catch (e) {
                return false;
            }
        }
    }

    getFUllScreenPlayer(ytId, type = "MAIN", duration = 0) {
        return `<div class='if_div'></div><iframe class='playerFrame fullScreenFrame' src='/yt_player.html?screen=FULL&v=${ytId}&type=${type}&duration=${duration}' frameborder='0' scrolling='no' allowTransparency='true'></iframe>`;
    }

    getCenterBoxPlayer(ytId, type = "MAIN", duration = 0) {
        return `<div class='if_div'></div><iframe class='playerFrame centerBoxBanner' src='/yt_player.html?screen=CB&v=${ytId}&type=${type}&duration=${duration}' frameborder='0' scrolling='no' allowTransparency='true'></iframe>`;
    }

    reset(type) {
        try {
            if (!type) {
                document.getElementById("full_screen_box").innerHTML = "";
                document.getElementById("yt_adplay_box").innerHTML = "";
            } else if (type === ScreenType.FULL) {
                document.getElementById("full_screen_box").innerHTML = "";
            } else if (type === ScreenType.CENTER_BOX) {
                document.getElementById("yt_adplay_box").innerHTML = "";
            }
            this.clear(type);
        } catch (e) {

        }
    }

    async postMessage(options) {
        switch (options?.type) {
            case "youtubeError":
                await LogManager.log(JSON.stringify({ error : true , errorType : options?.errorType, log : `msg : ${options?.msg}, playerType: ${options?.playerType}, failedId: ${options?.failedId}, screenType: ${options?.screenType} `}))
            break;
        }
    }

}

export default YoutubeManager;
