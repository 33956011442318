//@ts-nocheck
import {CbPlayerInfoType, Elem, EventType, PlayerType, logger, WindowDefine} from "@/src/js/define/core/Declare";
import Helper from "@/src/helper/Helper.js";

class FeaturedIconElem extends Elem{

    constructor() {
        super();
    }

    init(){
        this.$contents = this.findElem("FeaturedIconElem");
        this.isVodStyle = false;
        this.selectors();
        this.events();
    }

    selectors(){
        this.featuredIconLISelector = ".featuredIcon_area>li";
        this.vodLISelector = ".vod_t>ul>li";
        this.bandBannerSelector = ".bandBanner";
        this.$vodArea = $(".vod_area");
    }

    events(){
        let _this = this;

        this.$contents.on(EventType.MOUSE_ENTER, _this.featuredIconLISelector , function(event:any){
            const key = $(event.currentTarget).data("id");
            const dataValue = DataDefine.ins().find(DataDefine.ICON_MAIN)[key];
            const $currentVodArea = _this.$vodArea.filter("[data-id='"+key+"']");
            _this.$vodArea.removeClass("on");

            if( $currentVodArea ){
                $currentVodArea.addClass("on")
                $currentVodArea.find(".curationBannerList>li").eq(0).addClass("on");
                const curationData = DataDefine.ins().find(DataDefine.CURATION_BANNER).find( (item:any) => item.position === "POS"+key );
                if( curationData?.videoMode ==="THUMB_7" ){
                    return;
                }

                const dataValue = curationData?.details?.[0] ?? {};

                if( dataValue["videoProcessType"] === "NONE" ) return;
                if( dataValue["youtubeId"] === "" || dataValue["youtubeId"] === undefined ) return;
                if( dataValue?.["videoEventCodeView"] ){
                    GAManager.send({ eventCode : dataValue["videoEventCodeView"], adType : "VIDEO", type : dataValue?.contentType });
                }
                WindowDefine.ElemDefine.ins().find( WindowDefine.CenterBoxPlayerElem ).createPlayer( {
                    ytId : dataValue["youtubeId"],
                    bannerImg : Helper.PUBLIC_CDN_URL + dataValue["playerAttachImagePath"],
                    type : PlayerType.FEATURED_BANNER_ELEM,
                    infoType : CbPlayerInfoType.IMAGE,
                    currentData: dataValue,
                    delay: 0
                } )


            }

        })

        this.$contents.on(EventType.MOUSE_ENTER, _this.bandBannerSelector , function(event:any){
            logger.event( _this.constructor.name, _this.bandBannerSelector, EventType.MOUSE_LEAVE );
            const key = $(event.currentTarget).closest(".vod_area").data("id");
            const $currentVodArea = _this.$vodArea.filter("[data-id='"+key+"']");
            $currentVodArea.addClass("on");

            const curationData = DataDefine.ins().find(DataDefine.CURATION_BANNER).find( (item:any) => item.position === "POS"+key );

            if( curationData?.videoMode ==="THUMB_7" ){
                $currentVodArea.find(".curationBannerList>li").eq(0).addClass("on");
            }

            clearTimeout(WindowDefine.UIManager.ins().playerTimer)
        });

        this.$contents.on(EventType.MOUSE_LEAVE, _this.bandBannerSelector, function(){
            logger.event( _this.constructor.name, _this.bandBannerSelector, EventType.MOUSE_LEAVE );
            _this.$vodArea.removeClass("on");
            // YoutubeManager.ins().clear();
        })


        this.$contents.on(EventType.MOUSE_LEAVE, _this.featuredIconLISelector, function(){
            logger.event( _this.constructor.name, _this.featuredIconLISelector, EventType.MOUSE_LEAVE );
            _this.$vodArea.removeClass("on");
            WindowDefine.YoutubeManager.ins().clear();
            clearTimeout(WindowDefine.UIManager.ins().playerTimer)
        })


    }

}

export default FeaturedIconElem;
