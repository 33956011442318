//@ts-nocheck
import {CbPlayerInfoType, Elem, EventType, PlayerType, logger, WindowDefine} from "@/src/js/define/core/Declare";
import Helper from "@/src/helper/Helper.js";
class FeaturedBannerElem extends Elem{

    constructor() {
        super();
    }

    init(){
        this.$content = this.findElem('FeaturedBannerElem');

        this.events();
    }

    events(){
        const _this = this;

        this.$content.on(EventType.MOUSE_ENTER, "li", function(event:any){
            logger.event( _this.constructor.name, "[data-pd=FeaturedBannerElem] li", EventType.MOUSE_ENTER );
            clearTimeout(WindowDefine.UIManager.ins().playerTimer)

            const key = $(event.currentTarget).data("position")
            const dataValue = DataDefine.ins().find(DataDefine.FEATURED_BANNER).find( ( item ) => item["position"] === key );
            const isPlayerDisabled = DataDefine.ins().find( DataDefine.PCROOM_DATA ).pcroom[PlayerType.FEATURED_BANNER_ELEM];

            if( !dataValue || isPlayerDisabled ) return;

            WindowDefine.UIManager.ins().playerTimer = setTimeout(function(){
                if( dataValue["videoProcessType"] === "NONE" ) return;
                if( dataValue["youtubeId"] === "" || dataValue["youtubeId"] === undefined ) return;
                if( dataValue?.["videoEventCodeView"] ){
                    GAManager.send({ eventCode : dataValue["videoEventCodeView"], adType : "VIDEO", type : dataValue?.contentType });
                }
                WindowDefine.ElemDefine.ins().find( WindowDefine.CenterBoxPlayerElem ).createPlayer( {
                    ytId : dataValue["youtubeId"],
                    bannerImg : Helper.PUBLIC_CDN_URL + dataValue["playerAttachImagePath"],
                    type : PlayerType.FEATURED_BANNER_ELEM,
                    infoType : CbPlayerInfoType.IMAGE,
                    currentData: dataValue
                } )
            }, WindowDefine.UIManager.ins().playerDelay );
        });

        this.$content.on(EventType.MOUSE_LEAVE, "li", function(){
            logger.event( _this.constructor.name, "[data-pd=FeaturedBannerElem] li", EventType.MOUSE_LEAVE );
            clearTimeout( WindowDefine.UIManager.ins().playerTimer)
            WindowDefine.YoutubeManager.ins().clear();
        });

    }
}

export default FeaturedBannerElem;
