import { Elem , EventType, logger } from "@/src/js/define/core/Declare";

class CategoryIconElem extends Elem{

    constructor() {
        super();
    }

    init() {
        this.events();
    }

    events() {
        $(".run_area li>button").on(EventType.CLICK, function(e:JQuery.ClickEvent){
            logger.event( this, ": .run_area li>button clicked", e.target );
        });
    }

}

export default CategoryIconElem;
