class ElemDefine extends Singleton {
    constructor() {
        super();
        this.all = [];
        this._map = new Map();
    }
    add(cls) {
        const _cls = cls.constructor.name
        this._map.set(_cls, cls);
        this.all.push(cls)
        return cls;
    }
    find(cls) {
        const _cls = cls.name
        return this._map.get(_cls);
    }
}

export default ElemDefine;


