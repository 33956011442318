//@ts-nocheck

const processData = (data) => {
    const result = {};
    for (const [key, value] of Object.entries(data)) {
        result[key] = separateData(value);
    }
    return result;
}

const separateData = (data) => {
    let type = Array.isArray(data) ? "array" : typeof data === 'object' && data !== null ? "object" : "unknown";
    const contentType = type === "array" ? data[0]?.contentType : type === "object" ? data?.contentType : "unknown";
    let withoutImages = []; // 쎔네일 이미지가 없는 경우
    let withoutPositions = []; // 포지션이 비어있는 경우 (순서대로 1~9)
    let withoutYoutubeIds = []; // 광고 오프 없이, 시작 동영상 유튜브 ID가 없는 경우 & 동영상 플레이어 선택시 유튜브 ID가 없는 경우
    let withoutWebUrls = []; // 광고오프 없이, 시작/엔딩 브라우저 웹 URL이 없는 경우
    let withoutClickWebUrls = []; // 웹 프로세스의 CLICK이벤트가 있는데 웹 URL이 없는 경우
    let withoutGameNames = []; // 게임 프로세스의 게임실행 있는데 게임 이름이 없는 경우


    const extNum = (str)=>{
        return typeof str !== 'string' ? str : parseInt(str.match(/\d+/)?.[0] || '0', 10);
    }

    const emptyData = (_data, _length ) =>{
        return Array.from({ length: _length }, (_, i) => i + 1)
            .filter(i => !_data.some(d => extNum(d.position) === i))
            .map(i => ({ empty: true, position: `POS${i}` }));
    }

    // 배열인 경우
    if (type === "array") {
        switch( contentType ){
            case "ICON_MAIN":
                withoutImages = data.filter(item => !hasImagePath(item)).map(item => item?.id);
                withoutPositions = emptyData(data, 9);
                break;
            case "FEATURED_BANNER":
                withoutImages = data.filter(item => !hasImagePath(item)).map(item => item?.id);
                withoutPositions = emptyData(data, 4);
                break;
            case "CURATION_BANNER":
                withoutImages = null;
                withoutPositions = null;
                break;
            case "ICON_SECTION" :
                withoutImages = data.filter(item => !hasImagePath(item)).map(item => item?.id);
                withoutPositions = emptyData(data, 7);
            break;
        }
    }
    // 객체인 경우
    else if (type === "object") {
        switch( contentType ){
            case "CHARACTER_BANNER":
                withoutImages = !hasImagePath(data) ? [data.id] : [];
                break;
            case "BRAND_BANNER":
                withoutImages = !hasImagePath(data) ? [data.id] : [];
                break;
            case "CONTENTBAR":
                withoutImages = !hasImagePath(data) ? [data.id] : [];
                break;
            case "START_VIDEO":
                withoutYoutubeIds = !data?.campaignId ? [] : !data?.youtubeId ? [data.id] : [];
                // console.log("START_VIDEO :: ", withoutYoutubeIds, data?.youtubeId , !data?.campaignId );
                break;
            case "START_BRS":
                withoutWebUrls = !data?.campaignId ? [] : !data?.landingWebUrl ? [data.id] : [];
                // console.log("START_BRS :: ", withoutWebUrls, data?.landingWebUrl , !data?.campaignId );
                break;
            case "END_BRS":
                withoutWebUrls = !data?.campaignId ? [] : !data?.landingWebUrl ? [data.id] : [];
                // console.log("END_BRS :: ", data);
                break;
            case "BRS_TARGET":
                //     console.log("BRS_TARGET :: ", data);
                break;
            case "SECTION_BOARD_A" :
                withoutImages = !hasImagePath(data) ? [data.id] : [];
                withoutWebUrls = !hasWebUrl(data) ? [data.id] : [];
                // withoutWebUrls = data.filter(item => !hasWebUrl(item)).map(item => item?.id);
                break;
        }
    }

    // return { contentType, withoutImages, withoutPositions, withoutYoutubeIds , withoutWebUrls };
    return { contentType, withoutPositions };
}

const hasImagePath = (item)=> {
    return item?.brandAttachImagePath ||
        item?.imagePath ||
        item?.thumbnailImagePath ||
        item?.imageUrl ||
        item?.iconAttachImagePath ||
        item?.bannerAttachImagePath ||
        item?.characterAttachImagePath ||
        item?.sbAttachImagePath ||
        item?.thumbnailUrl;
}

const hasWebUrl = (item)=> {
    return item?.landingWebUrl;
}


const  logErrorsForProcessedData = (processedData)=> {
    const errorMessages = [];

    for (const [key, value] of Object.entries(processedData)) {
        const errorProps = Object.keys(value).filter(prop =>
            prop.startsWith('without') &&
            Array.isArray(value[prop]) &&
            value[prop].length > 0
        );

        if (errorProps.length > 0) {
            errorMessages.push(`Error in ${key}: ${errorProps.join(', ')}`);
        }
    }

    return errorMessages;
}

export { processData, separateData, hasImagePath , logErrorsForProcessedData};
