// @ts-nocheck
import {CbPlayerInfoType, Elem, EventType, PlayerType, WindowDefine, logger} from "@/src/js/define/core/Declare";
import Helper from "@/src/helper/Helper.js";

class CurationBannerElem extends Elem{

    constructor() {
        super();
    }

    init(){
        this.$contents = this.findElem("FeaturedIconElem");
        this.selectors();
        this.events();
    }

    selectors(){
        this.vodLISelector = ".vod_t>ul>li";
        this.$vodArea = $(".vod_area");
    }

    events(){
        const _this = this;

        this.$contents.on(EventType.CLICK, "li.item", function(event:any){
            const ytId:string = $(event.currentTarget).data("ytid") as string;
            logger.event(_this.constructor.name, "li.item", EventType.CLICK, ytId);
        });

        this.$contents.on(EventType.MOUSE_ENTER, _this.vodLISelector, function(event:any){
            logger.event( _this.constructor.name, _this.vodLISelector, EventType.MOUSE_ENTER );
            $(event.currentTarget).addClass("on").siblings().removeClass("on");

            const index = $(event.currentTarget).data("index");
            const key = $(event.currentTarget).closest(".vod_area").data("id");
            const $currentVodArea = _this.$vodArea.filter("[data-id='"+key+"']");
            $currentVodArea.addClass("on");


            const dataValue = DataDefine.ins().find(DataDefine.CURATION_BANNER).find( (v:any) =>
                v["position"] === "POS"+key
            );

            if( dataValue === undefined || dataValue?.videoMode === "THUMB_7" ) return;

            const currentData = dataValue["details"][index];

            if( currentData["youtubeId"] ){
                $(event.currentTarget).addClass("on").siblings().removeClass("on");
                WindowDefine.ElemDefine.ins().find( WindowDefine.CenterBoxPlayerElem ).createPlayer( {
                    ytId : currentData["youtubeId"],
                    bannerImg : Helper.PUBLIC_CDN_URL+currentData["playerAttachImagePath"],
                    type : PlayerType.FEATURED_ICON_ELEM,
                    currentData : currentData
                })

                if( currentData?.videoEventCodeView ){
                    GAManager.send({ eventCode : currentData?.videoEventCodeView, adType : "VIDEO"  });
                }
            }else{
                WindowDefine.YoutubeManager.ins().clear();
                console.log("currentData youtubeId is undefined");
            }
        });

        this.$contents.on(EventType.MOUSE_LEAVE, _this.vodLISelector, function(event:any) {
            logger.event( _this.constructor.name, _this.vodLISelector, EventType.MOUSE_LEAVE );
            $(event.currentTarget).removeClass("on").siblings().removeClass("on");
            clearTimeout(WindowDefine.UIManager.ins().playerTimer)
            _this.$vodArea.removeClass("on");
            WindowDefine.YoutubeManager.ins().clear();
            console.log("vodLISelector MOUSE_LEAVE");
        });
    }
}

export default CurationBannerElem;

