import {Elem, WindowDefine, EventType, ScreenType} from "@/src/js/define/core/Declare";

class UtilBarElem extends Elem {

    constructor() {
        super();
    }

    init() {
        this.$gameOnOffToggle = $(".toggle_game_on_off>.toggle");
        this.$sphsToggle = $(".toggle_sp_hs>.toggle");
        this.$utilButtons = $(".bottom_area .set_area button");
        this.events();
    }

    events() {
        const _this = this;

        this.$utilButtons.on(EventType.CLICK, function (event:any) {
            if (WindowDefine.YoutubeManager.ins().isPlaying(ScreenType.FULL) && !$(event.currentTarget).hasClass("btn_middle_sound")) {
                WindowDefine.ElemDefine.ins().find(WindowDefine.MusicStationElem).stop();
            }
        });

        this.$sphsToggle.on(EventType.CHANGE, function (event:any) {
            $(event.currentTarget).siblings(".round").toggleClass("on");
            if ($(event.currentTarget).is(':checked')) {
                console.log(_this, ".bottom_area .set_area .toggle_sp_hs", "헤드셋")
            } else {
                console.log(_this, ".bottom_area .set_area .toggle_sp_hs", "스피커")
            }
            CommandManager.ins().toggleSpHs( $(event.currentTarget).is(':checked') );
        });

        this.$gameOnOffToggle.on(EventType.CHANGE, function ( event:any ) {
            if ($(event.currentTarget).is(':checked')) {
                $(".contentsArea").addClass("game_off")
                WindowDefine.ElemDefine.ins().find( WindowDefine.MusicStationElem ).play();
            } else {
                $(".contentsArea").removeClass("game_off");
                WindowDefine.ElemDefine.ins().find( WindowDefine.MusicStationElem ).stop();
            }
            WindowDefine.ElemDefine.ins().find( WindowDefine.MusicStationElem ).GaSend("MSTN_GSWITCH_CLICKS");
        });
    }

    toggleGameAndMusic(isMusicAcitvation: boolean) {
        if (isMusicAcitvation) {
            $(".contentsArea").addClass("game_off");
            this.$gameOnOffToggle.prop("checked", true);
        } else {
            $(".contentsArea").removeClass("game_off");
            this.$gameOnOffToggle.prop("checked", false);
        }
    }
}


export default UtilBarElem;

