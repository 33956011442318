//@ts-nocheck
import {
    CbPlayerInfoType,
    Elem,
    EventType,
    PlayerType,
    WindowDefine
} from "@/src/js/define/core/Declare";
import Helper from "@/src/helper/Helper.js";

class CenterBoxPlayerElem extends Elem{

    constructor() {
        super();

    }

    init(){
        this.createPlayerTimer = null;
        this.createHTMLElement();
        this.selectors();
        this.events();
    }

    createHTMLElement() {
        $(".contents").prepend( this.markup() );
    }
    selectors(){
        this.$mainAdBoxArea = $('.main_adbox_area');
        this.closeYtPlayerSelector = ".closeYtPlayer";
        this.$textType = this.$mainAdBoxArea.find(".textType");
        this.$imgType = this.$mainAdBoxArea.find(".imgType");
    }

    events(){
        this.$mainAdBoxArea.find(this.closeYtPlayerSelector).on(EventType.CLICK, () =>{
            this.clear();
        });

        this.$imgType.on(EventType.CLICK, async function(){

        });

        this.$mainAdBoxArea.find("#yt_adplay_box").on(EventType.CLICK, async function(){
            try{
                const urlSrc = $(".centerBoxBanner").attr("src");
                const urlParams = new URLSearchParams(urlSrc.split('?')[1]);
                const type = urlParams.get('type');
                if( type === PlayerType.START_VOD ){
                    const dataValue = DataDefine.ins().find( DataDefine.START_VIDEO );
                    const eventCodeClick = dataValue["landingEventCodeClick"];
                    if( dataValue?.landingWebUrl ){
                        CommandManager.ins().webOpen( dataValue.landingWebUrl );
                    }
                    GAManager.send( { eventCode : eventCodeClick , adType : eventCodeClick } );
                }
            }catch (e){
                console.log("yt_adplay_box click Error");
            }


        });

    }

    async startup(){
        const dataValue = DataDefine.ins().find( DataDefine.START_VIDEO );
        if( dataValue === undefined ) return;

        this.createPlayer( {
            ytId : dataValue["youtubeId"],
            bannerImg : Helper.PUBLIC_CDN_URL + dataValue["playerAttachImagePath"],
            type : PlayerType.START_VOD,
            infoType : CbPlayerInfoType.IMAGE,
            hasCloseBtn : true,
            currentData : dataValue
        } );
    }

    createPlayer( paramObj: { ytId?: string, bannerImg?: string, hasCloseBtn?: boolean, type?: any, infoType?: any, currentData?:any, delay?:number }){
        const notAccessYoutube = window.__PRELOADED_MAIN_STATE__?.NOT_ACCESS_YOUTUBE;
        if( notAccessYoutube ){
            return;
        }

        const isPlayerDisabled = DataDefine.ins().find( DataDefine.PCROOM_DATA ).pcroom[paramObj?.type];
        if( isPlayerDisabled ){
            return;
        }

        paramObj = paramObj === undefined ? {} : paramObj;
        const ytId = paramObj.ytId === undefined ? "" : paramObj.ytId;
        const bannerImg = paramObj.bannerImg === undefined ? "" : paramObj.bannerImg;
        const hasCloseBtn = paramObj.hasCloseBtn === undefined ? false : paramObj.hasCloseBtn;
        const type = paramObj.type === undefined ? "NONE" : paramObj.type;
        const delay = paramObj.delay === undefined ? WindowDefine.UIManager.ins().playerStateDelay : paramObj.delay;

        if( ytId === undefined || ytId === "" ) return;

        this.clear();

        this.$mainAdBoxArea.find(this.closeYtPlayerSelector)[ hasCloseBtn ? "show" : "hide" ]();

        this.$imgType.find(">img").attr("src", bannerImg);

        WindowDefine.ElemDefine.ins().find( WindowDefine.MusicStationElem ).stop();

        clearTimeout( this.createPlayerTimer );
        if( delay === 0  ){
            this.createCenterBoxHTML( ytId, type , paramObj.currentData?.videoPlayTime ?? 0 );
        }else{
            this.createPlayerTimer =  setTimeout( ()=>{
                this.createCenterBoxHTML( ytId, type , paramObj.currentData?.videoPlayTime ?? 0 );
            }, delay );
        }

    }

    clear(){
        if( document.querySelector(".centerBoxBanner") ){
            try{
                const elem = document.querySelector(".centerBoxBanner") as HTMLIFrameElement;
                const contentWindow = elem.contentWindow as any;
                contentWindow.cont_youtube("pause");
            }catch (e){

            }
            $(".centerBoxBanner").hide();
        }
        this.$imgType.removeClass("active");
        this.$textType.removeClass("active");
        this.$mainAdBoxArea.removeClass("active");
    }

    removePlayer(){
        this.clear();
    }

    createCenterBoxHTML( ytId:string, type:any, duration:any ) {
        try{
            if( document.querySelector(".centerBoxBanner") ){
                $(".centerBoxBanner").show();
                const elem = document.querySelector(".centerBoxBanner") as HTMLIFrameElement;
                const contentWindow = elem.contentWindow as any;
                contentWindow.changeVideoId( ytId, type, duration  );
            }else{
                ( document.getElementById("yt_adplay_box") as HTMLElement).innerHTML = WindowDefine.YoutubeManager.ins().getCenterBoxPlayer( ytId, type, duration   );
            }
        }catch (e){
           console.log("centerBoxBanner changeVideoId Error");
        }

        this.$mainAdBoxArea.addClass("active");
    }

    markup(){
        return `
            <div class='main_adbox_area'>
                <div class='yt_left'>
                    <div class='imgType info_area'>
                        <img src='' alt=''>
                    </div>
                    <button class='btn_left btn_left_bottom closeYtPlayer'>영상 닫기</button>
                </div>
                <div id='yt_adplay_box'></div>
            </div>
        `;
    }

}

export default CenterBoxPlayerElem;

