import { processData, logErrorsForProcessedData } from "./contentErrorNotifier.js";
import axios from "axios";

const getIp = (req ) => {
    let ip = null;
    if (req?.headers["x-forwarded-for"]) {
        ip = req.headers["x-forwarded-for"].split(',')[0]
    } else if (req?.headers["x-real-ip"]) {
        ip = req?.connection?.remoteAddress
    } else {
        ip = req?.connection?.remoteAddress
    }
    ip = ip === "::1" ? process.env.MY_IP : ip;
    return ip;
}

const getLauncherType = (req) => {
    return req.query?.type;
}

const cLog = async (...data) => {
    try{
        const result = await fetch('/api/v1/log', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        return await result.json();
    }catch(e){
        // console.log("Error api log call");
        return {};
    }
}

const handleDataProcessing = async( _data, req)=> {
    try {
        const processedData = processData(_data);
        const errors = logErrorsForProcessedData(processedData);
        if (errors?.length > 0) {
            try{
                const result = await axios.post(
                    `${process.env.DOMAIN}/api/v1/log`,
                    {
                        error: true,
                        log: `count : ${errors.length}, msg : ${errors?.join(" / ")}, clientIP:${getIp(req)} header:{accept-language:${req?.headers["accept-language"]}, user-agent=${req?.headers['user-agent']}} query: ${JSON.stringify(req.query)}`
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                );
            }catch (e){

            }
        }
    } catch (e) {
        // console.log("Error processing mainData:", e);
    }
}

const findExposureTag = (data, tag) => {
    const findId = Object.keys(data).find( key => key == tag );
    return findId ? data[findId] : data["default"];
}



export default {
    getIp,
    cLog,
    getLauncherType,
    handleDataProcessing,
    findExposureTag
}
