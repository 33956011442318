class DataDefine extends Singleton {
    static BRAND_BANNER = "BRAND_BANNER";
    static CURATION_BANNER = "CURATION_BANNER";
    static CONTENTBAR = "CONTENTBAR";
    static CHARACTER_BANNER = "CHARACTER_BANNER";
    static FEATURED_BANNER = "FEATURED_BANNER";
    static ICON_MAIN = "ICON_MAIN";
    static START_VIDEO = "START_VIDEO";
    static MUSIC_STATION = "MUSIC_STATION";
    static START_BRS = "START_BRS";
    static END_BRS = "END_BRS";
    static BRS_TARGET = "BRS_TARGET";
    static PCROOM_DATA = "PCROOM_DATA";

    static iconType = {
        GAME : "GAME",
        BRAND : "BRAND",
    }

    static webProcessType = {
        WEB : "WEB",
        NONE : "NONE",
        SECTION : "SECTION"
    }

    static videoProcessType = {
        CB : "CB",
        FULL : "FULL",
    }
W
    constructor() {
        super();
        this.all = [];
        this._map = new Map();
    }

    reset( data ){
        Object.keys(data).forEach( key => {
            this._map.set(key, data[key]);
            this.all.push(data[key]);
        });
    }

    add(obj) {
        this._map.set(obj.name, obj);
        this.all.push(obj)
        return obj;
    }

    find(name) {
        if (name === undefined) {
            return this._map.get("data");
        }
        return this._map.get(name);
    }

    async initData(_mq) {
        const _this = this;
        let mLive = "";
        try {
            DataDefine.ins().reset(window.__PRELOADED_MAIN_STATE__.mainData);
            DataDefine.ins().add( { name : DataDefine.PCROOM_DATA, ...window.__PRELOADED_MAIN_STATE__?.pcroomData  } );
        } catch (e) {
            LogManager.send({message: "DataDefine.initData() error", error: e});
        }
    }
}

export default DataDefine;
