//@ts-nocheck

import { Elem, logger, WindowDefine,  EventType} from "@/src/js/define/core/Declare";
class MusicStationElem extends Elem{

    constructor() {
        super();
    }

    init(){
        this.playIndex = -1;
        this.data = DataDefine.ins().find(DataDefine.MUSIC_STATION);
        this.selectors();
        this.createHTMLElement();
        this.events();
        this.timer = null;

        this.GA_CODES = {
            PLAY: "MSTN_PLAY_CLICKS", // 플레이 버튼 클릭
            SWITCH: "MSTN_GSWITCH_CLICKS", // 게임 / 뮤직 전환 버튼 클릭
            PAUSE: "MSTN_HOLD_CLICKS", // 일시정지 버튼 클릭
            NEXT: "MSTN_LIST_CHG_CLICKS", // 뮤직 리스트 전환 버튼 클릭
            PREV: "MSTN_LIST_CHG_CLICKS", // 뮤직 리스트 전환 버튼 클릭
            UNMUTE: "MSTN_unmute_CLICKS", // 음소거 해제 버튼 클릭
            MUTE: "MSTN_mute_CLICKS", // 음소거 버튼 클릭
            LIST: "MSTN_LIST_OPEN_CLICKS", // 목록 패널 열기 버튼 클릭
            CLOSE: "MSTN_LIST_CLOSE_CLICKS", // 목록 패널 닫기 버튼 클릭
            GROUP_1 : "MSTN_LIST_1_CLICKS", // 뮤직 그룹 1 클릭
            GROUP_2 : "MSTN_LIST_2_CLICKS", // 뮤직 그룹 2 클릭
            GROUP_3 : "MSTN_LIST_3_CLICKS", // 뮤직 그룹 3 클릭
            GROUP_4 : "MSTN_LIST_4_CLICKS", // 뮤직 그룹 4 클릭
        }

    }

    selectors(){
        this.$mov_play = $('.mov_play');
        this.$mov_pause = $('.mov_pause');
        this.$mov_list = $('.mov_list');
        this.$mov_close = $('.mov_close');
        this.$mov_content = $('.mov_content');
        this.$mov_title = $(".mov_bottom .title_name .name");
        this.$mov_content_box = $(".mov_content>ul");
        this.$button_prev = $(".mov_content .button_prev");
        this.$button_next = $(".mov_content .button_next");

        this.$mov_mute = $(".mov_mute");
        this.$mov_unmute = $(".mov_unmute");
    }

    GaSend(code: string): void {
        GAManager.send({eventCode: code, adType: "MS", type : "MUSIC_STATION"});
    }


    createHTMLElement(){

        let html = "";
        let createTitle = "";
        this.page = 0;
        this.perPage = 4;
        this.maxPage = 0;

        try{
            this.data.music_data.forEach( (item:any) => {
                const sortOrders = Object.keys(item).sort( function(a, b){
                    return parseInt(item[a].order) - parseInt(item[b].order);
                })

                this.maxPage = sortOrders.length -1;

                sortOrders.forEach( (key, orderIdx) => {
                    const list = item[key].list.sort( function(a:any, b:any){
                        return parseInt(a.num) - parseInt(b.num);
                    });

                    list.forEach( (listItem:any, index:number) => {
                        if( orderIdx === 0 && index === 0 ){
                            createTitle  = listItem.yt_title;
                        }

                        html += `
                              <li class='item' data-id='${listItem.yt_id}' data-index='${index + (orderIdx * list.length)}' data-command-id="musicStation" data-page='${orderIdx}' data-title='${listItem.yt_title}' data-event-code="${listItem?.event_code_view ?? ""}">
                                <div class='thumbnail'>
                                  <img src='${listItem.yt_thumbnail}' alt=''>
                                </div>
                                <div class='info'>
                                  <p class='title'>${listItem.yt_title}</p>
                                  <span class='play_txt'>지금 재생중</span>
                                </div>
                              </li>`;
                    });
                });
            });

            this.$mov_content_box.html(html);
            this.$mov_content_box.find("[data-page='0']").addClass("visible");
            this.$mov_title.html( createTitle );
        }catch(e){
            console.log( "musicStationData is not exist" );
        }

    }

    events(){
        const _this = this;
        this.$mov_play.on(EventType.CLICK, this.play.bind(this) );
        this.$mov_pause.on(EventType.CLICK, this.stop.bind(this));
        this.$mov_list.on(EventType.CLICK, this.open.bind(this));
        this.$mov_close.on(EventType.CLICK, this.close.bind(this));

        this.$mov_play.on(EventType.CLICK, function(){
            _this.GaSend( _this.GA_CODES.PLAY );
        });

        this.$mov_pause.on(EventType.CLICK, function(){
            _this.GaSend( _this.GA_CODES.PAUSE );
        });

        this.$mov_mute.on(EventType.CLICK, function(){
            _this.$mov_mute.hide();
            _this.$mov_unmute.show();
            WindowDefine.ElemDefine.ins().find( WindowDefine.FullScreenElem ).unmute();
            _this.GaSend( _this.GA_CODES.UNMUTE );
        });

        this.$mov_unmute.on(EventType.CLICK, function(){
            _this.$mov_mute.show();
            _this.$mov_unmute.hide();
            WindowDefine.ElemDefine.ins().find( WindowDefine.FullScreenElem ).mute();
            _this.GaSend( _this.GA_CODES.MUTE );
        });

        this.$mov_content.on(EventType.CLICK, ".item", function() {
            const index = parseInt( $(this).data("index") );
            if( _this.playIndex === index ) return;
            _this.play.bind(_this)(index);
        });

        const isMovContentNorMovListClicked = (target) => {
            return (!target.closest('.mov_content') && !target.closest('.mov_list') && !target.closest('.mov_close') && !target.closest('.mov_play') && !target.closest('.mov_pause') && !target.closest('.mov_mute') && !target.closest('.mov_unmute') );
        };

        $(document).on(EventType.CLICK, function(e) {
            if( isMovContentNorMovListClicked(e.target) ){
                _this.$mov_list.show();
                _this.$mov_close.hide();
                _this.$mov_content.removeClass("on");

            }
        });

        // 버튼 온오프 체크
        const checkButton = function(){
            _this.page === _this.maxPage ? _this.$button_next.addClass("off") : _this.$button_next.removeClass("off");
            _this.page === 0 ? _this.$button_prev.addClass("off") : _this.$button_prev.removeClass("off");
        }

        // 페이지 visbile
        const showPage = function(){
            $(".mov_content ul").find(".item").each(function(){
                const page = parseInt( $(this).data("page") );
                if( page === _this.page ) {
                    $(this).addClass("visible");
                }else{
                    $(this).removeClass("visible");
                }
            })
        }

        checkButton();


        this.$button_prev.on(EventType.CLICK, function(){
            if( _this.page === 0 ) return;
            _this.page--;
            checkButton();
            showPage();

            _this.GaSend( _this.GA_CODES.PREV );
        });

        this.$button_next.on(EventType.CLICK, function(){
            if( _this.page === _this.maxPage ) return;
            _this.page++;
            checkButton();
            showPage();

            _this.GaSend( _this.GA_CODES.NEXT );
        });
    }


    open(){
        logger.event( this.constructor.name, ".mov_list", EventType.CLICK );
        this.$mov_list.hide();
        this.$mov_close.show();
        this.$mov_content.addClass("on");
        this.GaSend( this.GA_CODES.LIST );
    }

    close(){
        logger.event( this.constructor.name, ".mov_close", EventType.CLICK );
        this.$mov_list.show();
        this.$mov_close.hide();
        this.$mov_content.removeClass("on");
        this.GaSend( this.GA_CODES.CLOSE );
    }

    play(index:number){
        logger.event( this.constructor.name, ".mov_play", EventType.CLICK );

        this.$mov_play.hide();
        this.$mov_pause.show();

        if( this.playIndex === -1 ){
            this.playIndex = 0;
        }

        this.playIndex = typeof index !== 'number' ? this.playIndex : index;

        const $currentElem = this.$mov_content_box.find(".item").filter("[data-index='"+this.playIndex+"']");
        const title = $currentElem.find(".title").text();
        const id = $currentElem.data("id");

        this.$mov_title.html( title );
        this.$mov_content_box.find(".item").filter("[data-index='"+this.playIndex+"']").addClass("on").siblings().removeClass("on");
        WindowDefine.ElemDefine.ins().find( WindowDefine.CenterBoxPlayerElem ).removePlayer();
        clearTimeout( this.timer );
        this.timer = setTimeout( function(){
            WindowDefine.ElemDefine.ins().find( WindowDefine.FullScreenElem ).createPlayer( id );
            WindowDefine.ElemDefine.ins().find( WindowDefine.UtilBarElem ).toggleGameAndMusic(true);
        }, WindowDefine.UIManager.ins().playerStateDelay );
    }

    stop(){
        clearTimeout( this.timer );
        logger.event( this.constructor.name, ".mov_pause", EventType.CLICK );
        this.$mov_play.show();
        this.$mov_pause.hide();
        // this.$mov_list.show();
        // this.$mov_close.hide();
        // this.$mov_content.removeClass("on");
        WindowDefine.ElemDefine.ins().find( WindowDefine.FullScreenElem ).removePlayer();
        WindowDefine.ElemDefine.ins().find( WindowDefine.UtilBarElem ).toggleGameAndMusic(false);
        // YoutubeManager.ins().clear( ScreenType.FULL );
    }

}


export default MusicStationElem;
