import { Elem , EventType, logger } from "@/src/js/define/core/Declare";

class BrandBarElem extends Elem{

    constructor() {
        super();
    }

    init(){
        this.events();
    }

    events(){
        $(".brand_bar_box>button").on(EventType.CLICK, function(){
            logger.event( this, ": brand_bar_box>button clicked" );
        });
    }
}

export default BrandBarElem;


